<template>
  <b-card
    v-loading="loading"
    class="statement-editor"
  >
    <el-form
      ref="form"
      label-width="80px"
      :label-position="'right'"
      :model="form"
    >
      <b-row>
        <b-col md="3">
          <el-form-item
            label="标题"
            prop="title"
            :rules="[{ required: true, message: '标题不能为空', trigger: 'change' }]"
          >
            <el-input
              v-model="form.title"
              :disabled="lId ? true : false"
              maxlength="40"
              placeholder="请输入名称"
            />
          </el-form-item>
        </b-col>
        <b-col md="3">
          <el-form-item label="更新日期">
            <el-date-picker
              v-model="form.updateTime"
              placement="bottom-start"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd"
              type="date"
              placeholder="选择日期"
            />
          </el-form-item>
        </b-col>
        <b-col md="3">
          <el-form-item label="生效日期">
            <el-date-picker
              v-model="form.effectiveTime"
              placement="bottom-start"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd"
              type="date"
              placeholder="选择日期"
            />
          </el-form-item>
        </b-col>
        <b-col md="3">
          <el-form-item label="版本">
            <el-input
              v-model="form.version"
              :disabled="lId ? true : false"
              placeholder="请输入版本"
            />
          </el-form-item>
        </b-col>
        <b-col md="3">
          <el-form-item label="业务名称"  prop="productIdList"
                        :rules="[{ required: true, message: '业务名称不能为空', trigger: 'change' }]">
            <el-select
              v-model="form.productIdList"
              multiple
              :disabled="lId ? true : false"
            >
              <el-option
                v-for="(item,index) in productOptions"
                :key="index"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
        </b-col>
        <b-col md="3">
          <el-form-item
            label="适用地区"
            prop="area"
            :rules="[{ required: true, message: '请选择地区', trigger: 'change' }]"
          >
            <el-input
              v-model="form.area"
              v-input-filter
              maxlength="100"
              placeholder="请输入区域"
            />
          </el-form-item>
        </b-col>
        <b-col md="3">
          <el-form-item
            label="语言版本"
            prop="language"
            :rules="[{ required: true, message: '请选择语言版本', trigger: 'change' }]"
          >
            <el-select v-model="form.language">
              <el-option
                v-for="(item,index) in languageOptions"
                :key="index"
                :label="item.label"
                :value="item.label"
              />
            </el-select>
          </el-form-item>
        </b-col>
      </b-row>
    </el-form>
    <div class="flex-start match-height editor-wrap">
      <div class="doc-left">
        <div class="add-doc">
          <el-button
            class="btn-block text-center"
            size="small"
            type="primary"
            @click="addSection"
          >
            添加新模块
          </el-button>
        </div>
        <VuePerfectScrollbar
          v-loading="tagLoading"
          :style="{height: pageH - 380 +'px'}"
          :settings="settings"
        >
          <draggable
            v-model="form.infoModels"
            handle=".mover"
            tag="ul"
            :options="{forceFallback:true, fallbackClass:'draggingStyle'}"
            @end="onEnd"
          >
            <li
              v-for="(item,index) in form.infoModels"
              :key="index"
              :class="activeIndex == index ? 'doc-active' : ''"
              class="section-item"
              @click="sectionClick(item,index)"
            >
              <div
                v-show="!item.editor"
                class="flex-start mover"
                style="width: 100%"
              >
                <feather-icon
                  icon="CircleIcon"
                  class="mr10"
                />
                <el-tooltip
                  v-if="item.directoryName.length >= 14"
                  class="item"
                  effect="light"
                  :content="item.directoryName"
                  placement="top"
                >
                  <span
                    class="overflow-ellipsis directoryName"
                    style="max-width: 70%"
                  >{{ item.directoryName }}</span>
                </el-tooltip>
                <span
                  v-else
                  class="overflow-ellipsis directoryName"
                  style="max-width: 70%"
                >{{ item.directoryName }}</span>
              </div>
              <div
                v-show="!item.editor"
                class="flex-end section-operate"
              >
                <i
                  class="el-icon-edit mr10"
                  @click="editorShow(item)"
                />
                <i
                  class="el-icon-delete"
                  @click.stop="delSection(index)"
                />
              </div>
              <el-input
                v-show="item.editor"
                v-model="item.directoryName"
                v-input-filter
                maxlength="100"
                @blur="updateDirectoryName(item,index)"
              >
                <template slot="append">
                  <i
                    class="el-icon-check"
                    @click="updateDirectoryName(item,index)"
                  />
                </template>
              </el-input>
            </li>
          </draggable>
        </VuePerfectScrollbar>
      </div>
      <div class="doc-right">
        <div
          v-for="(item,index) in form.infoModels"
          v-if="activeIndex == index"
          :key="index"
        >
          <EditorVue
            :content.sync="item"
            :page-height="pageH - 380"
          />
          <!--          <Toolbar-->
          <!--            style="border-bottom: 1px solid #ccc"-->
          <!--            :editor="editor"-->
          <!--            :default-config="toolbarConfig"-->
          <!--            :mode="mode"-->
          <!--          />-->
          <!--          <Editor-->
          <!--            v-model="item.content"-->
          <!--            style="overflow-y: hidden;"-->
          <!--            :style="{height: pageH - 450 +'px'}"-->
          <!--            :default-config="editorConfig"-->
          <!--            :mode="mode"-->
          <!--            @onCreated="onCreated"-->
          <!--          />-->
        </div>
      </div>
    </div>
    <div class="dialog-footer text-center mt20">
      <el-button
        size="small"
        @click="back"
      >
        返 回
      </el-button>
      <el-button
        type="primary"
        size="small"
        @click="onSubmit"
      >
        保 存
      </el-button>
      <el-button
        size="small"
        type="primary"
        @click="preview"
      >
        预 览
      </el-button>
    </div>
  </b-card>
</template>

<script>
import { Editor, Toolbar } from '@wangeditor/editor-for-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import { GetDetailById, SaveTemplate, GetTemplateContentByOptions } from '@/api/enterprise/statement'
import { error, success } from '@core/utils/utils'
import draggable from 'vuedraggable'
import EditorVue from '@core/components/editor-vue/EditorVue'
import { getAllProduct } from '@/api/enterprise/product'

export default {
  components: {
    VuePerfectScrollbar, Editor, Toolbar, draggable, EditorVue,
  },
  data() {
    return {
      loading: false,
      languageOptions: [
        { label: '中文简体', value: 1 },
        { label: '中文繁体', value: 2 },
        { label: '英文', value: 3 },
        { label: '日语', value: 4 },
        { label: '韩语', value: 5 },
        { label: '泰语', value: 6 },
      ],
      countryProps: {
        emitPath: false,
        // multiple: true,
      },
      form: {
        version: '',
        title: '',
        updateTime: '',
        effectiveTime: '',
        area: '',
        templateCode: this.$route.query.tId,
        productIdList: [],
        language: '',
        id: Number(this.$route.query.id),
        infoModels: [],
      },
      productOptions: [],
      tagLoading: false,
      settings: {
        maxScrollbarLength: 60,
        wheelSpeed: 0.30,
      },
      activeIndex: 0,
      isInit: true,
      tId: this.$route.query.tId,
      pId: this.$route.query.pId,
      lId: this.$route.query.lId,
      c: this.$route.query.c,
      htmlStr: '',
      editor: null,
      editorConfig: { placeholder: '请输入内容...' },
      mode: 'default', // or 'simple'
    }
  },
  created() {
    this.getAllProduct()
    if (this.lId) {
      this.getTemplateContentByOptions()
    } else {
      this.queryTemplateById()
    }
  },
  mounted() {
    const navItems = document.getElementsByClassName('nav-item')
    navItems.forEach(navTag => {
      const childTag = navTag.getElementsByClassName('menu-title')[0]
      if (childTag) {
        const title = navTag.getElementsByClassName('menu-title')[0].textContent
        if (title === '隐私声明管理') {
          navTag.className = 'nav-item active'
          // eslint-disable-next-line no-param-reassign
          navTag.parentNode.style = 'display: block'
        }
      }
    })
  },
  destroyed() {
    const navItems = document.getElementsByClassName('nav-item')
    navItems.forEach(navTag => {
      const childTag = navTag.getElementsByClassName('menu-title')[0]
      if (childTag) {
        const title = navTag.getElementsByClassName('menu-title')[0].textContent
        if (title === '隐私声明管理') {
          if (this.$route.path === '/statement') {
            navTag.className = 'nav-item active'
          } else {
            navTag.className = 'nav-item'
          }
        }
      }
    })
  },
  methods: {
    preview() {
      // 没保存之前保存在浏览器缓存
      let htmlStr = ''
      this.form.infoModels.forEach(d => {
        if (d.content) {
          // eslint-disable-next-line no-const-assign
          htmlStr += d.content
        }
      })
      if (htmlStr) {
        localStorage.setItem('htmlStr', htmlStr)
        const routeUrl = this.$router.resolve({
          path: '/statement-read',
        })
        window.open(routeUrl.href, '_blank')
      } else {
        error('内容为空，无法预览')
      }
    },
    getTemplateContentByOptions() {
      GetTemplateContentByOptions(this.lId).then(res => {
        const resData = res.data
        if (resData.code === 0) {
          const { infoModels } = resData.data
          this.form.title = resData.data.title
          this.form.version = resData.data.version
          this.form.productIdList = resData.data.productIdList
          this.form.templateId = resData.data.templateId
          infoModels.forEach(item => {
            this.form.infoModels.push({
              contentId: item.contentId,
              content: item.content,
              directoryName: item.directoryName,
              editor: false,
            })
          })
        } else {
          error(resData.msg)
        }
      })
    },
    getAllProduct() {
      getAllProduct().then(res => {
        const resData = res.data
        if (resData.code === 0) {
          this.productOptions = resData.data
        }
      })
    },
    queryTemplateById() {
      this.loading = true
      GetDetailById(this.form.id).then(res => {
        this.loading = false
        const resData = res.data
        if (resData.code === 0) {
          this.form = resData.data
          this.form.infoModels.forEach(item => {
            this.$set(item, 'editor', false)
          })
          if (this.c) {
            this.form.title = `${this.form.title}-副本`
            this.form.id = 0
          }
        } else {
          error(resData.msg)
        }
      })
    },
    onEnd(event) {
      this.activeIndex = event.newIndex
    },
    back() {
      if (this.$route.query.id) {
        this.$router.push({
          path: '/statement',
        })
      } else {
        this.$router.push({
          path: '/statement-save',
          query: {
            id: this.$route.query.tId,
            title: this.form.title,
            version: this.form.version,
            productIdList: this.form.productIdList,
          },
        })
      }
    },
    addSection() {
      this.form.infoModels.push({
        content: '',
        directoryName: '',
        editor: true,
      })
      this.activeIndex = this.form.infoModels.length - 1
      this.htmlStr = this.form.infoModels[this.activeIndex].content
    },
    delSection(index) {
      this.$confirm('是否确认删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        closeOnClickModal: false,
      }).then(() => {
        this.form.infoModels.splice(index, 1)
        this.activeIndex = 0
        this.htmlStr = this.form.infoModels[0].content
      })
    },
    updateDirectoryName(item, index) {
      if (item.directoryName.length === 0) {
        this.form.infoModels.splice(index, 1)
      } else {
        item.editor = false
      }
    },
    editorShow(item) {
      this.form.infoModels.forEach(s => {
        s.editor = false
      })
      item.editor = true
    },
    sectionClick(item, index) {
      // this.htmlStr = item.content
      this.activeIndex = index
    },
    onCreated(editor) {
      this.editor = Object.seal(editor) // 一定要用 Object.seal() ，否则会报错
    },
    change() {
      if (!this.isInit) {
        this.form.infoModels[this.activeIndex].content = this.htmlStr
      }
      this.isInit = false
    },
    onSubmit() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.loading = true
          const newInfoModels = []
          this.form.infoModels.forEach((m, index) => {
            if (m.directoryName.length > 0) {
              newInfoModels.push(m)
            }
          })
          this.form.infoModels = newInfoModels
          SaveTemplate(this.form).then(res => {
            this.loading = false
            const resData = res.data
            if (resData.code === 0) {
              success(resData.msg)
              this.$router.push({
                path: '/statement',
              })
            } else {
              error(resData.msg)
            }
          })
        }
      })
    },
  },
}
</script>

<style lang="scss">
.directoryName {
  cursor: move;
}
.doc-dropdown .btn-secondary,
.doc-dropdown .btn-secondary:hover {
  border: none !important;
  background-color: transparent !important;
  box-shadow: none !important;
}
.feather-more-vertical {
  color: #6e6b7b !important;
}
.editor-wrap {
  height: 100%;
  border: 1px solid #ebe9f1;
  align-items: baseline;
  border-radius: 4px;
  .doc-left {
    width: 260px;
    border-right: 1px solid #ebe9f1;
    height: 100%;
    .add-doc {
      padding: 10px;
    }
    .section-item {
      padding: 10px;
      cursor: pointer;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .icon-w_wendang {
        margin-right: 10px;
      }
      i {
        font-size: 20px;
      }
      .el-input-group__append,
      [dir] .el-input-group__prepend {
        padding: 0 5px !important;
      }
    }
    .section-operate {
      display: none;
      position: absolute;
      right: 10px;
    }
    .section-item:hover {
      .section-operate {
        display: block;
      }
    }
    .section-item.doc-active {
      border-left: 2px solid #0d40b2;
      color: #0d40b2;
      position: relative;
    }
    .tag-title {
      padding: 10px;
      border-top: 1px solid #ebe9f1;
      border-bottom: 1px solid #ebe9f1;
      align-items: center;
      /*color: #b9b9c3;*/
    }
  }
  .doc-right {
    width: calc(100% - 260px);
    height: 100%;
    .doc-search-wrap {
      width: 100%;
      padding: 0 20px;
      border-bottom: 1px solid #ebe9f1;
      height: 40px;
      .doc-search-left {
        width: 80%;
        .el-input__inner {
          border: none !important;
        }
      }
      .el-dropdown {
        display: flex;
        align-items: center;
      }
    }

    .doc-li {
      /*transition: all .2s;*/
      cursor: pointer;
      padding: 20px 10px;
      border-bottom: 1px solid #ebe9f1;
      color: #6e6b7b;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .feather-more-vertical {
        margin-right: 10px;
        position: relative;
        top: 2px;
        opacity: 0;
      }
      .documentName {
        /*margin-left: 10px;*/
        font-size: 12px;
        color: #6e6b7b;
      }
    }
    .doc-li:hover {
      transform: translateY(-4px);
      box-shadow: 0 3px 10px 0 #ebe9f1;
      .feather-more-vertical {
        opacity: 1;
      }
    }
  }
}
.statement-editor .el-date-editor.el-input,
.statement-editor .el-date-editor.el-input__inner{
  width: 100%;
}
</style>
<style>
.w-e-text-container table td,
.w-e-text-container table th {
  border: 1px solid var(--w-e-textarea-border-color);
  padding: 3px 5px;
}
</style>
